import CaseStudyFeed from "components/case-study/CaseStudyFeed";
import CaseStudyHeader from "components/case-study/CaseStudyHeader";
import CaseStudyLogoSlider from "components/case-study/CaseStudyLogoSlider";
import FeaturedCaseStudySlider from "components/case-study/FeaturedCaseStudySlider";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudies() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus Case Studies - A Safer Future Starts Here</title>
        <meta
          name="description"
          content="Read how the Rhombus Platform improved security and operations for commercial businesses, school districts, retail, and more."
        />
        <script
          src="https://fast.wistia.com/embed/medias/bny3yv3e5l.jsonp"
          async
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>
      <CaseStudyHeader />
      <CaseStudyLogoSlider />
      <FeaturedCaseStudySlider />
      <CaseStudyFeed />
      <TrialBanner />
    </GlobalLayout>
  );
}
