import { Tag } from "components/page/newHeader/styles/HeaderStyles";
import { ButtonBaseOutlined } from "components/rhombus-UI/theme/buttons";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { PreTitle, Title } from "components/rhombus-UI/theme/typography";
import { graphql, Link, StaticQuery } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";

import TextLinkV2 from "../common/TextLinkV2";
import CaseStudyFilter from "./CaseStudyFilter";
import {
  CardTitle,
  CaseStudyCard,
  FeaturedCardContainer,
} from "./styles/caseStudyStyles";
import {
  caseStudiesWithFeaturedPostsFirst,
  getAllIndustriesInUse,
  isDateWithinLast7Days,
} from "./util/caseStudyFunction";

export default function CaseStudyFeed() {
  const renderContent = data => {
    const { edges: _caseStudies } = data.allMarkdownRemark;
    const SLICE_SIZE = 12;
    const [constantData, setConstantData] = useState([]);
    const [mutableData, setMutableData] = useState([]);
    const [currentFilter, setCurrentFilter] = useState([]);
    const [sliceEnd, setSliceEnd] = useState(SLICE_SIZE);

    useEffect(() => {
      const caseStudies =
        caseStudiesWithFeaturedPostsFirst(_caseStudies) || _caseStudies;
      setConstantData(caseStudies);
      setMutableData(caseStudies);
      setCurrentFilter(caseStudies);
    }, []);

    useEffect(() => {
      setMutableData(currentFilter.slice(0, sliceEnd));
    }, [sliceEnd, currentFilter]);

    useEffect(() => {
      setSliceEnd(SLICE_SIZE);
    }, [currentFilter]);

    const handleIncreaseSliceEnd = () => {
      if (sliceEnd >= currentFilter.length - 1) return;
      setSliceEnd(prev => prev + 6);
    };

    const renderLoadMoreButton = useCallback(() => {
      if (currentFilter.length - 1 > sliceEnd) {
        return (
          <ButtonBaseOutlined
            onClick={handleIncreaseSliceEnd}
            style={{ alignSelf: "center" }}
          >
            Load More Case Studies
          </ButtonBaseOutlined>
        );
      }
      return <></>;
    }, [currentFilter, sliceEnd]);

    return (
      <SectionContainer
        id="case-study-feed"
        style={{ overflow: "unset", padding: "5rem 0" }}
      >
        <SectionInner
          style={{
            flexDirection: "column",
            maxWidth: "unset",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Title style={{ textAlign: "center" }}>Customer Case Studies</Title>
          <CaseStudyFilter
            categories={getAllIndustriesInUse(_caseStudies)}
            data={constantData}
            setCurrentFilter={setCurrentFilter}
          />
          <FeaturedCardContainer>
            {mutableData.map(item => {
              const caseStudy = item.node;
              return (
                <CaseStudyCard as={Link} to={caseStudy.fields.slug}>
                  <div className="card-image-wrapper">
                    <img
                      src={caseStudy.frontmatter.heroImage}
                      className="card-image"
                      alt=""
                    />

                    {caseStudy.frontmatter.cardLogo && (
                      <img
                        src={caseStudy.frontmatter.cardLogo}
                        className="logo"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="inner">
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <PreTitle color="var(--blue-500)">
                        {caseStudy.frontmatter.industry}
                      </PreTitle>
                      {isDateWithinLast7Days(caseStudy.frontmatter.date) && (
                        <Tag>New</Tag>
                      )}
                      {caseStudy.frontmatter.featured && <Tag>Featured</Tag>}
                    </div>
                    <CardTitle
                      style={{
                        textAlign: "left",
                        fontSize: "24px",
                      }}
                      color="var(--blue-900)"
                    >
                      {caseStudy.frontmatter.pageTitle}
                    </CardTitle>
                    <div className="footer">
                      <TextLinkV2
                        title="Read Case Study"
                        color="var(--blue-500)"
                        path={caseStudy.fields.slug}
                      />
                    </div>
                  </div>
                </CaseStudyCard>
              );
            })}
          </FeaturedCardContainer>
          {renderLoadMoreButton()}
        </SectionInner>
      </SectionContainer>
    );
  };
  const pageQuery = graphql`
    query {
      allMarkdownRemark(
        limit: 1000
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: {
            templateKey: { eq: "case-study" }
            draft: { ne: true }
          }
        }
      ) {
        totalCount
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date
              companyLogo
              heroImage
              companyName
              pageTitle
              featured
              industry
              cardLogo
            }
          }
        }
      }
    }
  `;
  return <StaticQuery query={pageQuery} render={renderContent} />;
}
