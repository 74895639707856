import { css } from "linaria";
import { styled } from "linaria/react";
import React, { useEffect, useMemo, useRef, useState } from "react";

import Check from "../integrations/marketplace/img/checkmark.svg";
import Close from "../integrations/marketplace/img/close.svg";

export const filterContainer = css`
  display: flex;
  gap: 1.25rem;
  margin-bottom: 2.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  @media (max-width: 1024px) {
    padding-left: 2.5rem;
  }
  @media (max-width: 600px) {
    padding-left: 0;
  }
`;

export const chipContainer = css`
  display: flex;
  overflow: auto;
  gap: 1.25rem;
  align-items: center;
  scroll-behavior: smooth;
  padding: 0 2.5rem;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1024px) {
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      var(--nuetral-900) 10% 80%,
      transparent
    );
  }
`;

export const Chip = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--blue-500);
  border-radius: 30px;
  border: 1px solid var(--blue-500);
  text-align: center;
  font-size: 16px;
  flex-shrink: 0;
  padding: 5px 15px;
  font-weight: 350;
  line-height: 200%;
  transition: all 250ms;
  background-color: ${props => (props.active ? "#2a7de120" : "unset")};
  position: relative;
  overflow: hidden;
  img {
    transition: all 250ms;
  }
  input {
    position: absolute;
    height: 50px;
    width: 200px;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
  :has(input:checked) {
    background-color: #2a7de110;
  }

  :hover {
    background-color: #2a7de110;
  }
`;

const CaseStudyFilter = ({ categories, data, setCurrentFilter }) => {
  const [selections, setSelections] = useState({});
  const [iconStatus, setIconStatus] = useState({});
  const [filterArray, setFilterArray] = useState([]);

  const selectionsRef = useRef(selections);
  selectionsRef.current = selections;

  const handleMultiSelect = filter => {
    const checked = selections[filter].checked;
    setSelections({ ...selections, [filter]: { checked: !checked } });
  };
  useEffect(() => {
    categories.forEach(item => {
      setSelections(prev => ({ ...prev, [item]: { checked: false } }));
    });
    setFilterArray([]);
  }, []);

  useMemo(() => {
    const onlyTrue = Object.keys(selectionsRef.current).filter(
      key => selectionsRef.current[key].checked
    );
    setFilterArray(onlyTrue);
  }, [selections]);

  useMemo(() => {
    if (filterArray.length > 0) {
      const filteredByIndustry = data.filter(item => {
        const industry = item.node.frontmatter.industry;
        const found = filterArray.includes(industry);
        return found;
      });
      setCurrentFilter(filteredByIndustry);
    } else {
      setCurrentFilter(data);
    }
  }, [filterArray]);

  const handleMouseOver = filter => {
    setIconStatus({ ...iconStatus, [filter]: { closed: true } });
  };

  const handleMouseLeave = filter => {
    setIconStatus({ ...iconStatus, [filter]: { closed: false } });
  };

  return (
    <div className={filterContainer}>
      <div className={chipContainer}>
        {categories.map(item => (
          <Chip>
            <input
              type="checkbox"
              onChange={() => handleMultiSelect(item)}
              onMouseEnter={() => handleMouseOver(item)}
              onMouseLeave={() => handleMouseLeave(item)}
              checked={selections[item]?.checked}
            />
            <span>{item}</span>
            {selections[item]?.checked ? (
              <img
                src={iconStatus[item]?.closed ? Close : Check}
                alt="checkmark"
              />
            ) : (
              <></>
            )}
          </Chip>
        ))}
      </div>
    </div>
  );
};

export default CaseStudyFilter;
