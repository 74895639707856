import {
  DefaultCard,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { TitleSmall } from "components/rhombus-UI/theme/typography";
import { css } from "linaria";
import { styled } from "linaria/react";

export const FeaturedCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  max-width: 1390px;
  height: max-content;
  padding: 0 1.25rem;
  @media (max-width: 700px) {
    justify-content: center;
  }
`;

export const slideWrapperStyles = css`
  width: 800px !important;
  height: 400px !important;
  max-width: 100%;
  transition: all 250ms ease-in-out;
  color: inherit;
  box-shadow: 10px 10px 10px #03193320;
  border-radius: 1rem;
  :hover {
    transform: translateY(-0.25rem);
  }
  :hover .card-image {
    transform: scale(1.1);
  }
`;

export const relatedCaseStudySlideStyles = css`
  width: 400px !important;
  height: 450px !important;
  max-width: 100%;
  transition: all 250ms ease-in-out;
  color: inherit;
  box-shadow: 1px 1px 10px #03193320;
  border-radius: 1rem;
  :hover .card-image {
    transform: scale(1.1);
  }
`;

export const FeaturedCaseStudyCard = styled.a`
  height: 100%;
  width: 100%;
  display: flex;
  text-decoration: none;
  background: var(--nuetral-100);
  border-radius: 1rem;
  overflow: hidden;
  .logo {
    height: 50%;
    max-width: 50%;
    object-fit: contain;
    object-position: left;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  .card-image-wrapper {
    overflow: hidden;
    height: 100%;
    width: 50%;
    position: relative;
    background-color: var(--blue-800);
    flex-shrink: 0;
  }
  .card-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    transition: all 250ms ease-in-out;
    filter: opacity(0.5);
  }
  .inner {
    position: relative;
    padding: 1.25rem;
    display: inherit;
    flex-direction: column;
    align-self: flex-end;
    height: 50%;
    gap: 0.5rem;
    .footer {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      padding-bottom: 0.75rem;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    .card-image-wrapper {
      overflow: hidden;
      height: 50%;
      width: 100%;
      position: relative;
      background-color: var(--blue-800);
      flex-shrink: 0;
    }
    .inner {
      position: unset;
      .footer {
        padding-bottom: 0;
      }
    }
  }
`;

export const CaseStudyCard = styled(DefaultCard)`
  display: flex;
  flex-direction: column;
  transition: all 250ms ease-in-out;
  color: inherit;
  background: var(--white);
  .logo {
    height: 50%;
    max-width: 50%;
    object-fit: contain;
    object-position: left;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  .card-image-wrapper {
    overflow: hidden;
    height: 50%;
    position: relative;
    background-color: var(--blue-800);
    flex-shrink: 0;
  }
  .card-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    transition: all 250ms ease-in-out;
    filter: opacity(0.5);
  }
  .inner {
    padding: 1.25rem;
    display: inherit;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.5rem;
    .footer {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
    }
  }
  :hover {
    box-shadow: 1px 10px 15px #03193320;
    transform: ${props =>
      !props.slider ? "translateY(-0.25rem)" : "translateY(0)"};
  }
  :hover .card-image {
    transform: scale(1.1);
  }
`;

export const CardTitle = styled(TitleSmall)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
  font-size: 24px;
`;

export const FeaturedTag = styled.div`
  position: absolute;
  right: 0;
  top: -50%;
  padding: 0 1.25rem;
  background: var(--gradient-base);
  z-index: 2;
  border-radius: 2rem 0 0 2rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
  p {
    color: var(--white);
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
  }
  @media (max-width: 600px) {
    top: 1.25rem;
    background: var(--gradient-light);
    p {
      background: var(--text-gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const HeroVideo = styled.video`
  width: 100%;
  object-fit: cover;
  margin-right: -5rem;
  border-radius: 1rem;
  box-shadow: 1px 1px 10px #03193320;
  background-color: var(--blue-900);

  @media (max-width: 1440px) {
    border-radius: 1rem 0 0 1rem;
    margin-right: -10rem;
  }
  @media (max-width: 600px) {
    height: 250px;
  }
`;

export const FeaturedCaseStudy = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  box-shadow: 1px 1px 10px #03193320;
  position: absolute;
  width: 50%;
  padding: 1.25rem;
  gap: 1.25rem;
  bottom: -2.5rem;
  left: -1.25rem;
  border-radius: 1rem;
  .logo {
    height: 30px;
    width: 60px;
    object-fit: contain;
  }
  @media (max-width: 1024px) {
    left: 0.25rem;
  }
  @media (max-width: 600px) {
    left: 0;
    bottom: -3.25rem;
    * {
      font-size: 12px !important;
    }
  }
`;

export const HeroInner = styled(SectionInner)`
  gap: 5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  gap: 2rem;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const HeaderRight = styled.div`
  flex-grow: 1;
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
