import { SectionContainer } from "components/rhombus-UI/theme/containers";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css, cx } from "linaria";
import { styled } from "linaria/react";
import React from "react";

const SliderWrapper = styled(SectionContainer)`
  background: transparent;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  padding-top: 0;
`;

const sectionInner = css`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const carouselWrapper = css`
  max-width: 1390px;
  width: 100%;
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, transparent, #fff 10% 80%, transparent);
`;
const carouselInner = css`
  width: max-content;
  display: flex;
  gap: 2.5rem;
  animation: scroll 80s linear infinite;
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 1.25rem));
    }
  }
`;

const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  :hover {
    background: var(--white);
  }
`;

const logoStyles = css`
  transform: scale(0.7) !important;
  filter: opacity(0.5) !important;
`;

const logoStylesDarkBackground = cx(logoStyles, css``);

export default function CaseStudyLogoSlider({ dark }) {
  const renderContent = data => {
    const LOGO_SET_1 = [
      data.logo1,
      data.logo2,
      data.logo3,
      data.logo4,
      data.logo5,
      data.logo6,
      data.logo7,
      data.logo8,
      data.logo9,
      data.logo10,
      data.logo11,
      data.logo12,
      data.logo13,
      data.logo14,
      data.logo15,
      data.logo16,
    ];

    const logoRow1 = [...LOGO_SET_1, ...LOGO_SET_1];

    return (
      <SliderWrapper>
        <div className={sectionInner}>
          <div className={carouselWrapper}>
            <div
              className={carouselInner}
              // @ts-ignore
              style={{ "--width": logoRow1.length }}
            >
              {logoRow1.map((logo, index) => (
                <CarouselItem key={index}>
                  <GatsbyImage
                    image={getImage(logo)}
                    alt=""
                    objectPosition="center"
                    className={dark ? logoStylesDarkBackground : logoStyles}
                    loading="lazy"
                    objectFit="contain"
                  />
                </CarouselItem>
              ))}
            </div>
          </div>
        </div>
      </SliderWrapper>
    );
  };
  const GET_LOGOS = graphql`
    query {
      logo1: file(
        relativePath: { eq: "components/case-study/logos/stance.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 242, height: 59)
        }
      }
      logo2: file(
        relativePath: { eq: "components/case-study/logos/praesum.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 210, height: 59)
        }
      }
      logo3: file(
        relativePath: { eq: "components/case-study/logos/torcon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 88, height: 59)
        }
      }
      logo4: file(
        relativePath: { eq: "components/case-study/logos/luxer-one.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 173, height: 59)
        }
      }
      logo5: file(
        relativePath: { eq: "components/case-study/logos/colony.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 203, height: 24)
        }
      }
      logo6: file(relativePath: { eq: "components/case-study/logos/cau.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 82, height: 59)
        }
      }
      logo7: file(relativePath: { eq: "components/case-study/logos/ahf.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 53, height: 59)
        }
      }
      logo8: file(
        relativePath: { eq: "components/case-study/logos/10-fed.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 176, height: 59)
        }
      }
      logo9: file(
        relativePath: { eq: "components/case-study/logos/uacdc.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 59, height: 59)
        }
      }
      logo10: file(
        relativePath: { eq: "components/case-study/logos/blakes.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 147, height: 59)
        }
      }
      logo11: file(
        relativePath: { eq: "components/case-study/logos/jesuit.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 157, height: 59)
        }
      }
      logo12: file(
        relativePath: { eq: "components/case-study/logos/flax.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 193, height: 59)
        }
      }
      logo13: file(
        relativePath: { eq: "components/case-study/logos/birch.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 59, height: 59)
        }
      }
      logo14: file(relativePath: { eq: "components/case-study/logos/ca.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 118, height: 59)
        }
      }
      logo15: file(
        relativePath: { eq: "components/case-study/logos/in-shape.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 203, height: 59)
        }
      }
      logo16: file(
        relativePath: { eq: "components/case-study/logos/nemaha.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 79, height: 59)
        }
      }
    }
  `;
  return <StaticQuery query={GET_LOGOS} render={renderContent} />;
}
